import { gql } from "graphql-request";

import { gqlApi } from "./gql/gqlApi";

export interface RetryTokenInput {
  uploadId: string;
  type: string;
}

export interface RetryTokenInput {
  uploadId: string;
  type: string;
}

export const uploadDataApi = gqlApi.injectEndpoints({
  endpoints: (builder) => ({
    getUploadDataUrl: builder.query<string, string>({
      query: (type: string) => ({
        document: gql`
          query {
            uploadData(typeOf: ${type}) 
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return response.uploadData;
      },
    }),
    getRetryUploadDataUrl: builder.query<string, RetryTokenInput>({
      query: (retryToken: RetryTokenInput) => ({
        document: gql`
          query {
            retryToken(uploadId: "${retryToken.uploadId}", typeOf: ${retryToken.type}) 
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return response.retryToken;
      },
    }),
  }),
});

export interface SplatProgress {
  message?: string | null;
  state?: string | null;
  progress?: number | null;
}

export const splatProgressApi = gqlApi.injectEndpoints({
  endpoints: (builder) => ({
    getSplatProgress: builder.query<SplatProgress | undefined, string>({
      query: (relatedId: string) => ({
        document: gql`
          query {
            getProgress(dataId: "${relatedId}", progressType: SPLAT)
          }
        `,
      }),
      transformResponse: async (response: any) => {
        if (!response.getProgress) {
          return undefined;
        }
        const progress = {
          message: response.getProgress["message"],
          state: response.getProgress["state"],
          progress: response.getProgress["progress"],
        } as SplatProgress;
        return progress;
      },
    }),
  }),
});

export const { useLazyGetUploadDataUrlQuery, useLazyGetRetryUploadDataUrlQuery } = uploadDataApi;
export const { useLazyGetSplatProgressQuery } = splatProgressApi;
