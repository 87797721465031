import { useMemo } from "react";
import * as THREE from "three";

import { FrustumScene } from "./types";
import { setLayers } from "components/CameraSelector/Common/ThreeUtils";
import {
  DEFAULT_FRUSTUM_LENGTH_MULTIPLIER,
  defaultFrustumMaterial,
  RenderLayer,
} from "components/CameraSelector/Constants";
import FrustumBufferGeometry from "components/CameraSelector/ThreeWrappers/Frustum";
import { DeviceCameraInfo } from "hooks/useDeviceCamera";

export const FRUSTUM_NAME = "frustum";
export const WIRE_FRUSTUM_NAME = "wire frustum";

export const useFrustumScene = (
  deviceCamera: DeviceCameraInfo,
  maxObjectDim: number,
  contentParent: THREE.Group,
  layersDisabled?: boolean,
  frustumMaterial?: THREE.MeshBasicMaterial
): FrustumScene => {
  const { aspectRatio, verticalCameraFOV, cx, cy, projectionMatrix, rotation } = deviceCamera;

  const frustumGeometry = useMemo(() => {
    if (rotation % 2 === 1) {
      return new FrustumBufferGeometry(2 * aspectRatio, 2, projectionMatrix.m11, cy, cx, true);
    } else {
      return new FrustumBufferGeometry(2, 2 / aspectRatio, projectionMatrix.m00, cx, cy, true);
    }
  }, [aspectRatio, cx, cy, projectionMatrix, rotation]);

  const frustumGeometryNoFarPlanes = useMemo(() => {
    if (rotation % 2 === 1) {
      return new FrustumBufferGeometry(2 * aspectRatio, 2, projectionMatrix.m11, cy, cx, false);
    } else {
      return new FrustumBufferGeometry(2, 2 / aspectRatio, projectionMatrix.m00, cx, cy, false);
    }
  }, [aspectRatio, cx, cy, projectionMatrix, rotation]);

  const frustumCamera = useMemo(() => {
    const frust = new THREE.PerspectiveCamera(verticalCameraFOV, aspectRatio, 0.001, 10000);
    frust.projectionMatrix.set(
      projectionMatrix.m00,
      projectionMatrix.m01,
      projectionMatrix.m02,
      projectionMatrix.m03,
      projectionMatrix.m10,
      projectionMatrix.m11,
      projectionMatrix.m12,
      projectionMatrix.m13,
      projectionMatrix.m20,
      projectionMatrix.m21,
      projectionMatrix.m22,
      projectionMatrix.m23,
      projectionMatrix.m30,
      projectionMatrix.m31,
      projectionMatrix.m32,
      projectionMatrix.m33
    );
    frust.projectionMatrixInverse.copy(frust.projectionMatrix).invert();
    frust.layers.enable(RenderLayer.ANNOTATION_LAYER);
    contentParent.add(frust);
    return frust;
  }, [verticalCameraFOV, aspectRatio, projectionMatrix, contentParent]);

  const traverserCamera = useMemo(() => {
    const camera = frustumCamera.clone();
    camera.layers.disableAll();
    camera.layers.enable(RenderLayer.ANNOTATION_LAYER);
    camera.layers.enable(RenderLayer.OCCLUSSION_LAYER);
    return camera;
  }, [frustumCamera]);

  const frustum = useMemo(() => {
    const mesh = new THREE.Mesh(frustumGeometry, frustumMaterial ? frustumMaterial : defaultFrustumMaterial);
    const frustumLength = mesh.position.length() + DEFAULT_FRUSTUM_LENGTH_MULTIPLIER * (maxObjectDim / 2);
    mesh.scale.set(frustumLength, frustumLength, frustumLength);
    if (!layersDisabled) {
      setLayers(mesh, RenderLayer.SELECTOR_LAYER);
    }
    mesh.name = FRUSTUM_NAME;
    return mesh;
  }, [frustumGeometry, frustumMaterial, layersDisabled, maxObjectDim]);

  const wireFrustum = useMemo(() => {
    const frame = new THREE.LineSegments(new THREE.WireframeGeometry(frustumGeometryNoFarPlanes));
    frame.frustumCulled = false;
    frame.renderOrder = 1;
    if (!layersDisabled) {
      setLayers(frame, RenderLayer.SELECTOR_LAYER);
    }
    frame.scale.set(frustum.position.length(), frustum.position.length(), frustum.position.length());
    frame.name = WIRE_FRUSTUM_NAME;
    return frame;
  }, [frustum, layersDisabled, frustumGeometryNoFarPlanes]);

  return {
    frustumGeometryNoFarPlanes,
    frustumCamera,
    traverserCamera,
    frustum,
    wireFrustum,
  };
};
